.Container {
  height: 100%;
  width: 100%;
  position: relative;
}

.Map {
  position: relative;
  overflow: visible;
  height: calc(100% - 50px);
  width: 100vw;
}

.SelectedTipContainer {
  position: absolute;
  width: calc(100% - 32px);
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
}

.SelectedTip {
  border: solid var(--color-primary800) 2px;
}

.Filters {
  background-color: var(--color-grey700);
  padding: 4px 6px;
}
