.Header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-family: "Marcellus";
  width: 80vw;
}

.Header > h1 {
  margin-top: 48px;
  margin-bottom: 20px;
  color: var(--color-grey900);
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.Header > h4 {
  line-height: 38px;
  max-width: 768px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 400;
  color: var(--color-grey900);
  text-align: left;
}

.Content {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: var(--color-grey50);
  align-items: center;
  padding-bottom: 50px;
}

.CardGrid {
  margin-top: 30px;
  display: grid;
  column-gap: 25px;
  row-gap: 20px;
  grid-template-columns: 100%;
  justify-items: center;
  max-width: 1100px;
  margin: 0 10px;
}

.HeroTitle {
  padding-top: 50px;
}

.AboutCardStyle {
  padding: 60px 40px 40px 40px;
  width: 80vw;
  text-align: start;
  white-space: pre-wrap;
  align-items: flex-start;
}

.AboutCardStyle > div {
  transform: translateY(25px);
}

.AboutColor {
  color: var(--color-grey500);
}

.Started {
  color: var(--color-grey700);
}

.AboutCardStyle ul {
  padding-left: 20px;
}

.AboutCardStyle li {
  list-style: circle;
  color: var(--color-grey500);
}

.AboutHero {
  color: white;
}

.TitleWrapper {
  display: flex;
  justify-content: left;
  align-items: left;
  float: left;
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .CardGrid > h1 {
    display: flex;
    margin-top: 5vh;
    margin-right: auto;
    font-size: 30px;
  }
}
