.ProfileButtons {
  display: flex;
}

.Button {
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  fill: var(--color-primary600);
  font-weight: bold;
  font-size: 14px;
}

.Button * {
  color: var(--color-primary600);
}

.ListIcon {
  display: flex;
  margin-right: 3px;
  margin-bottom: 2px;
  margin-left: -7px;
}
.PepoleIcon {
  display: flex;
  margin-right: 3px;
  margin-bottom: 2px;
  fill: var(--color-primary600);
}

.Star {
  display: flex;
  margin-right: 3px;
  margin-bottom: 4px;
}

.Button:hover + .ToolTipWrapper {
  display: flex;
}
