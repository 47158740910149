.Container {
  display: flex;
  flex-flow: column;
  background-color: white;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 80px);
  transition: transform ease 0.2s;
  padding-bottom: 30px;
}

.Container > section > *:not(:first-child) {
  margin-top: 10px;
}

.Vstack {
  display: flex;
  flex-flow: column;
}

.Image {
  object-fit: cover;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin: 8px;
  margin-right: 10px;
}

.NoImage {
  width: 50px;
  margin-right: 10px;
}

.Name {
  color: black;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.User {
  display: flex;
  flex-flow: row;
  padding-top: 15px;
  margin-left: 16px;
}

.User > *:not(:first-child):not(.EditButton) {
  margin-top: 10px;
}

.UserCard {
  margin-bottom: 15px;
}

.EditButton {
  align-self: center;
  margin-top: 24px;
  font-size: 14px;
}

.TipLists > div {
  overflow: hidden;
}

.TipLists a > div > * {
  margin-left: 16px;
}

.Explorers > section {
  margin-bottom: 24px;
}

.TipLists > div > span {
  font-weight: 700;
  margin-left: 16px;
}

.TipLists a:not(:last-child) > div > div::after {
  content: "";
  background: var(--color-grey200);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 260px;
}

.TipLists a > div > div::after {
  content: "";
  background: var(--color-grey200);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 260px;
}

.CollapseButton {
  position: absolute;
  bottom: 30px;
  right: 16px;
}

.CollapseButton svg {
  fill: var(--color-primary600);
  height: 30px;
  width: 30px;
  padding: 5px;
}

.CollapseButton svg:first-child {
  position: relative;
  left: 22px;
}

.Content {
  overflow: hidden;
}

.Content:hover {
  overflow-y: auto;
}

@media only screen and (max-width: 1259px) {
  .Container {
    box-shadow: 8px 0px 16px -4px rgba(16, 24, 40, 0.1),
      4px 0px 6px -2px rgba(16, 24, 40, 0.05);
    padding-bottom: 50px;
  }
  .Content {
    max-height: calc(100% - 30px);
  }
  .CollapsedProfile {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 8px;
  }

  .Collapsed .Explorers {
    display: none;
  }

  .Collapsed .User {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 2px;
  }

  .Collapsed .Name {
    display: none;
  }
}

.CollapsedProfile {
  flex-direction: column;
  align-items: flex-end;
  margin-right: 8px;
}
