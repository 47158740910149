.Content {
  width: 100%;
  background-color: var(--color-grey700);
  color: white;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}

.Navigation path {
  fill: white;
}

.Navigation {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  position: fixed;
  width: 100%;
  padding: 16px 16px;
  top: 0;
  background-color: var(--color-grey700);
  z-index: 5;
}

.Navigation .Title {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 4px;
  max-width: calc(100vw - 180px);
}

.Title > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProfileCard {
  background-color: white;
  width: calc(100% - 32px);
  margin: 16px;
  height: auto;
  color: black;
  position: absolute;
  top: 0px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  margin-top: 64px;
}

.Image {
  object-fit: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 8px;
  margin-right: 10px;
  margin-top: 20px;
}

.AvatarIcon {
  object-fit: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 8px;
  margin-left: 40px;
  margin-top: 20px;
}

.ProfilePicture {
  margin-top: 30px;
}

.ProfileTopContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.Options {
  display: flex;
  margin-top: 10px;
  width: 32px;
}

.ContextOptions {
  min-width: 180px;
  margin-bottom: 20px;
}

.ContextOptions > *:not(:first-child) {
  margin-top: 30px;
}

.ContextMenu {
  display: flex;
}

.ContextOptions h4 {
  width: 343px;
  height: 28px;
  left: 0px;
  top: 16px;
  padding-bottom: 20px;
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--color-grey900);
}

.ContextOptions .CreateButton {
  color: var(--color-grey700);
  fill: var(--color-grey600);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 15px;
  align-items: center;
  font-family: "Marcellus";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.ContextOptions svg {
  height: 25px;
  width: 25px;
}

.ContextOptions .SignOut {
  color: var(--color-error600);
  fill: var(--color-error600);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 15px;
  align-items: center;
  font-family: "Marcellus";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

.ProfileImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileCard img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-top: 24px;
  margin-left: 32px;
}

.ProfileCard h2 {
  margin-top: 8px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.ProfileCard h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.ProfileStatisticsContainer {
  margin-top: 16px;
  padding-bottom: 24px;
}

.ProfileStatistics button {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-between;
  margin-left: 16px;
}

.ProfileStatistics svg {
  margin: 0;
  height: 22px;
  width: 21px;
}

.FollowButton {
  height: 32px;

  /* Text xs/Medium */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-align: center;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.BackButton {
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}
