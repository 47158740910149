.Content {
  display: flex;
  flex-direction: column;
  overflow: unset;
  height: calc(100vh - 66px);
  gap: 0;
  align-items: center;
}

.Wiki {
  width: calc(100% - 32px);
  overflow: visible;
  margin: 0 16px;
  padding-bottom: 66px;
}

.Main {
  width: 100%;
  padding: 0;
  overflow: visible;
  margin-top: 0;
}

.MapButton {
  background-color: var(--color-primary600);
  width: 84px;
  height: 40px;
  color: white;
  position: fixed;
  bottom: 10%;
  border: 1px solid #a3397e;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 7px;
  cursor: pointer;
  flex-direction: row;
  gap: 5px;
}

.MapIcon {
  margin-top: 3px;
  margin-left: 2px;
  fill: white;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.MapIcon svg {
  fill: white;
}
