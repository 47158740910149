.Container {
  align-self: flex-start;
}

.Image {
  object-fit: cover;
  border-radius: 8px;
  background-color: var(--color-grey400);
}

.Title {
  font-family: "Marcellus";
  color: var(--color-grey900);
  font-size: 16px;
  font-weight: 800;
  word-break: break-word;
  line-height: 24px;
  cursor: pointer;
}

.Info {
  font-family: "Open Sans";
  font-size: 14px;
  color: var(--color-grey400);
  font-weight: 400;
}

.InfoItemContainer {
  display: flex;
  align-items: center;
  height: 14px;
  margin-top: 5px;
}

.InfoItem {
  margin-right: 8px;
  font-family: "Open Sans";
  display: flex;
  align-items: center;
}

.InfoItem > span {
  margin-left: 4.5px;
}

.InfoItem .CategoryIcon {
  margin-left: 0px;
}

.InfoItem svg {
  fill: var(--color-grey400);
  height: 16px;
  width: 16px;
}

.Subtitle {
  font-size: 14px;
  font-weight: 600px;
  line-height: 20px;
  color: var(--color-primary600);
  cursor: pointer;
}

.User img {
  border-radius: 50%;
}

.User .Subtitle {
  color: var(--color-grey400);
  cursor: unset;
}

.BoxPosition {
  left: 20px;
  top: 34px;
}
