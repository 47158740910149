.Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Item h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  overflow-wrap: break-word;
  text-align: center;
  margin-top: 18px;
  min-width: 180px;
  color: white;
}

.ColumnCircles {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 16px;
}

.DarkCirkel {
  display: flex;
  height: 10px;
  width: 10px;
  background-color: var(--color-primary700);
  border-radius: 50%;
}

.LightCirkel {
  display: flex;
  height: 10px;
  width: 10px;
  background-color: var(--color-primary100);
  border-radius: 50%;
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RESIcon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
}
