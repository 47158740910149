.Container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 340px);
  min-height: 200px;
  text-align: center;
}

.Container > h4 {
  margin-bottom: 6px;
  font-weight: bold;
}
