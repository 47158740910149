.Container {
  display: flex;
  flex-flow: column;
}

.Container > h4 {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-grey900);
}

.Container > *:not(:first-child) {
  margin-top: 20px;
}

.Tiles {
  display: grid;
  column-gap: 22px;
  row-gap: 35px;
  padding-right: 8px;
}

@media only screen and (max-width: 900px) {
  .Container {
    width: calc(100% + 19px);
  }
  .Container > h4 {
    margin-left: 5vw;
    font-weight: bold;
  }
  .Tiles {
    display: flex;
    flex-direction: row;
    max-width: 95vw;
    margin-left: 5vw;
    overflow-x: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
    overflow-y: hidden;
  }
  .Tiles::-webkit-scrollbar {
    display: none;
  }
}

.Tile {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  background-color: #000000;
  color: #ffffff;
}

.Tile.Pending {
  background-color: var(--color-blue3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.BackDrop {
  width: 100%;
  height: 0;
}

.BackDrop > img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.Info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 1.8em;
  padding: 6px;
}

.Info > h4 {
  margin-bottom: 5px;
  font-weight: bold;
}

.Tile.Pending > .Info > h4,
.Tile.Pending > .Info > span {
  color: transparent;
  text-shadow: none;
  height: 28px;
  width: 200px;
  animation: var(--pending-pulse);
  border-radius: 5px;
}

.Tile.Pending > .Info > span {
  height: 18px;
  width: 170px;
}

.Container path {
  fill: var(--color-grey400);
}
