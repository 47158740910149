.Container {
  display: flex;
  flex-flow: column;
  position: relative;
}

.Container > h4 {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-grey900);
}

.Container > *:not(:first-child) {
  margin-top: 20px;
}

.Tiles {
  display: flex;
  gap: 24px;
  overflow: hidden;
  position: relative;
}

.Card {
  position: relative;
  transition: all 0.2s ease;
}

.BackDrop {
  width: 100%;
  height: 0;
}

.BackDrop > img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.Info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1);
  text-align: center;
  line-height: 1.8em;
  padding: 6px;
}

.Info > h4 {
  margin-bottom: 5px;
  font-weight: bold;
}

.ButtonLeft:hover {
  transform: scale(1.2) rotate(180deg);
}
.ButtonRight:hover {
  transform: scale(1.2);
}

.ButtonRight {
  position: absolute;
  right: -20px;
  transition: all 0.2s ease;
}

.ButtonLeft {
  position: absolute;
  left: -20px;
  transform: rotate(180deg);
  height: 40px;
  width: 40px;
  transition: all 0.2s ease;
}

.Disabled * {
  fill: var(--color-grey400);
  opacity: 0.9;
  cursor: initial;
}

.Disabled:hover.ButtonRight {
  transform: none;
}

.Disabled.ButtonLeft:hover {
  transform: rotate(180deg);
}

@media only screen and (max-width: 900px) {
  .Container > h4 {
    margin-left: 5vw;
    font-weight: bold;
  }
  .Tiles {
    flex-direction: row;
    max-width: 95vw;
    margin-left: 5vw;
    overflow-x: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
    overflow-y: hidden;
  }
  .Tiles::-webkit-scrollbar {
    display: none;
  }
}
