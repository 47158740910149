.Container {
  height: auto;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.Default {
  width: 390px;
  padding: 10px 40px 40px 40px;
  text-align: center;
  align-items: center;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 20px;
}

.Container > h4 {
  margin-top: 34px;
  font-size: 20;
  font-weight: 600;
  color: var(--color-grey900);
}

.Container > p {
  margin-top: 10px;
  font-weight: 400;
  color: var(--color-grey500);
}

.Icon {
  position: relative;
  margin-bottom: -10px;
  top: 0;
  transform: translateY(-50%);
}

@media only screen and (max-width: 900px) {
  .Container {
    border-radius: 0px;
    box-shadow: none;
  }

  .Default {
    width: 100vw;
    text-align: left;
    padding: 24px 20px 24px 20px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--color-grey100);
  }

  .Icon {
    margin-right: auto;
    margin-bottom: 40px;
    padding-right: 10px;
    transform: translateY(0%);
    transform: scale(75%);
  }

  .Title {
    margin-top: 20px;
  }

  .IconRow {
    display: flex;
    flex-direction: row;
  }

  .ContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
