.Container {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  list-style-type: "›";
  color: var(--color-grey500);
}

.Container > div:not(:last-child) {
  margin-bottom: 8px;
}

.Container > div {
  margin-right: 8px;
  line-height: 32px;
}

.Container span {
  margin-left: 8px;
}

.Container > div:last-child > span {
  display: none;
}

.Container > div > a {
  color: var(--color-grey500);
  font-weight: lighter;
}

.Container > div:last-child > a {
  color: #000000;
  font-weight: bold;
}
