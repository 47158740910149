.Container {
  display: flex;
  flex-flow: column;
}

.Scroll {
  overflow-y: scroll;
  height: calc(100vh - 178px);
  padding-bottom: 20px;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.Scroll::-webkit-scrollbar {
  display: none;
}

.IconButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ShareIcon {
  height: 30px;
  width: 30px;
}

.InLine {
  display: flex;
}

.MarginFollowIcon {
  margin-right: 5px;
  margin-left: -7px;
  fill: var(--color-grey700);
}

.ButtonMargin {
  margin-right: 10px;
}

.MarginAddIcon {
  height: 22px;
  width: 22px;
}

.AddButton {
  display: flex;
  margin-right: 10px;
  background-color: white;
  border: #d0d5dd 1px solid;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-grey700);
}

.AlignButtons {
  display: flex;
  gap: 6px;
}

.MarginMapIcon {
  margin-right: 7px;
  margin-top: 2px;
  fill: var(--color-grey700);
}

.MarginEditIcon {
  margin-right: 7px;
  fill: var(--color-grey700);
}

.Info {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Info > .Content {
  line-height: 1.8em;
  word-break: break-word;
  padding-right: 16px;
}

.Actions {
  display: flex;
  flex-flow: column;
  min-width: fit-content;
  align-items: flex-end;
}

.Actions > button {
  display: block;
}

.Actions > * + button {
  margin-top: 8px;
}

.Option {
  display: block;
  padding: 6px;
}

.Option > * {
  color: var(--color-gray0);
  fill: var(--color-gray0);
}

.Option.Remove > * {
  color: var(--color-red0);
  fill: var(--color-red0);
}

.Option + .Option {
  margin-top: 6px;
}

.Option > .Icon {
  vertical-align: middle;
  margin-right: 8px;
  width: 24px;
}

.TipListImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  border: var(--color-gray2) 1px solid;
}

.View {
  display: flex;
  align-items: center;
}

.View > *:not(:first-child) {
  margin-left: 15px;
}

.Map {
  border-radius: 15px;
  height: 350px;
  margin-bottom: 12px;
}

.Filters {
  font-size: 14px;
  display: flex;
  margin: 16px 0;
  width: fit-content;
  height: 50px;
}

.Filters input {
  display: none;
}

.Description {
  font-size: 18px;
  word-break: break-word;
}

.Tip {
  border-radius: 10px;
  margin: 8px 0;
}

.Tip:last-child {
  margin-bottom: 48px;
}

.Tip.Selected {
  border-color: var(--color-blue0);
}

.NoTipsContainer {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);
}

.NoTips {
  background: #ffffff;
  border: 4px dashed var(--color-grey400);
  box-sizing: border-box;
  border-radius: 8px;
  text-align: left;
  padding: 20px;
}

.NoTips > .AddTipsButton {
  margin-top: 16px;
  fill: #ffffff;
}

.NoTips > .AddTipsButton > svg {
  margin-right: 6px;
  position: relative;
  top: 2px;
  height: 16px;
}

.Vstack {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Hstack {
  display: flex;
  flex-direction: row;
}

.CreateTipNo {
  margin-top: 20px;
}

.TextC {
  margin-top: 10px;
  color: var(--color-grey500);
  font-size: 16px;
}

.TextChead {
  color: var(--color-grey900);
  font-size: 18px;
}

.LoadingTips {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.UserItemContainer {
  display: none;
}

.EditButton {
  color: var(--color-grey700);
  fill: var(--color-grey600);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  margin-right: 80px;
}

.DeleteButton {
  color: var(--color-grey700);
  fill: var(--color-grey600);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.OptionsIcon > span {
  background-color: var(--color-grey700);
}

.ListOptions {
  width: 40px;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  margin-right: 10px;
}

.OptionsHeader {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1500px) {
  .Map {
    height: 270px;
  }
}

@media only screen and (max-width: 900px) {
  .Scroll {
    overflow-y: visible;
    height: auto;
    padding-bottom: 0;
    margin-bottom: -24px;
    margin-top: 56px;
    min-height: 100vh;
  }
  .Filters {
    margin-left: 16px;
    width: calc(100vw - 16px);
    overflow-x: scroll;
    margin-bottom: 0;
    margin-top: 6px;
    align-items: center;
  }
  .Filters::-webkit-scrollbar {
    display: none;
  }
  .Filters span:first-child {
    display: none;
  }
  .Map {
    display: none;
  }
  .UserItem {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 100%;
    height: 64px;
  }

  .UserItem > div {
    height: 64px !important;
  }

  .UserItemContainer {
    display: block;
    width: 100%;
    padding: 8px 16px;
    height: 84px;
    background-color: var(--color-grey700);
  }
  .UserItemContainer .UserItem * {
    color: white;
    fill: white;
  }
}
