.Content {
  width: 100%;
  display: grid;
  grid-template-columns: max-content auto max-content;
  gap: 19px;
  z-index: 3;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 80px);
}

.UserColumn {
  width: 300px;
}

.Main {
  margin-top: 15px;
  justify-self: center;
  width: min(1200px, 100%);
}

.Wiki {
  overflow-y: scroll;
  height: calc(100vh - 80px);
  scrollbar-width: none !important;
  -ms-overflow-style: none;
  justify-self: flex-end;
  margin-right: 16px;
  width: 300px;
}

.Wiki::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1450px) {
  .Wiki {
    width: 260px;
  }
  .MarginLeftColumn {
    width: 260px;
  }
}

@media only screen and (max-width: 1259px) {
  .Content {
    grid-template-columns: auto max-content;
  }
  .UserColumn {
    left: -230px;
    width: 300px;
    position: absolute;
    z-index: 5;
    height: 100%;
  }
  .Main {
    padding-left: 90px;
  }
  .Wiki {
    width: 240px;
  }
}

@media only screen and (max-width: 1049px) {
  .Wiki {
    width: 207px;
  }
}
