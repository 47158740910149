.Container {
  display: flex;
  flex-flow: column;
}

.Container > *:not(:first-child) {
  margin-top: 20px;
}

.Tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  gap: 15px;
}

.Tile {
  display: flex;
  flex-flow: column;
  border-radius: 15px;
  border: var(--color-gray2) 1px solid;
  padding: 20px;
}

.Tile > *:not(:first-child) {
  margin-top: 20px;
}

.Tile.Pending {
  background-color: var(--color-blue3);
}

.Tile > blockquote {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-gray0);
  line-height: 1.2em;
}

.Tile:not(.Pending) > blockquote::before {
  content: "“";
}

.Tile:not(.Pending) > blockquote::after {
  content: "”";
}

.Tile.Pending > blockquote > span {
  display: block;
  height: 20px;
  margin-bottom: 6px;
  content: "";
  border-radius: 5px;
  animation: var(--pending-pulse);
}

.Tile.Pending > blockquote > span:nth-child(2) {
  width: calc(100% - 40px);
}

.Tile.Pending > blockquote > span:nth-child(3) {
  width: calc(100% - 20px);
}
