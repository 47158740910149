.Header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  color: #ffffff;
  align-items: center;
  gap: 50px;
}

.Item {
  margin: 0 16px;
  max-width: 30vw;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  gap: 18px;
}

.MobileTitle {
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 28px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
}

.Header > section {
  display: flex;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  justify-content: center;
  gap: 60px;
}

.MainSearch {
  height: 50px;
  text-shadow: none;
  align-items: center;
}

.MainSearchWrapper {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.Content {
  display: flex;
  flex-flow: column;
  padding-top: 40px;
  align-items: center;
}

.About {
  color: var(--color-grey600);
  width: 100%;
  background-color: var(--color-grey50);
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.About svg {
  display: none;
}
