.Container {
  display: flex;
  flex-flow: column;
  border-radius: 8px;
  align-self: flex-start;
  margin-top: 10px;
}

.Title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: white;
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  padding: 8px;
  padding-left: 16px;
}

.Container:first-child {
  margin-top: 12px;
}

.Container > :not(:first-child) {
  margin-top: 16px;
}

.UniverseTitle {
  background: linear-gradient(90.69deg, #462d87 3.23%, #843b6a 106.26%);
}

.PopularTitle {
  background: linear-gradient(90.93deg, #627395 0%, #425775 100%);
}

.Popular {
  /*filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)); /*Lägg till när blurr problemet är löst*/
}

/*.Universe {
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1))
    drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
}*/

.TipLists,
.Tips {
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.TipLists {
  padding-bottom: 8px;
}

.TipLists button {
  margin: 0;
  margin-left: 12px;
}

@media only screen and (max-width: 1049px) {
  .TipLists a > div > div {
    padding-left: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .Container {
    margin-top: 0;
  }
  .Container:first-child {
    margin-top: 0;
  }
}
