.Content {
  width: 100%;
  background-color: var(--color-grey700);
  color: white;
  height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.Navigation path {
  fill: white;
}

.Title {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.Navigation {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  font-size: 16px;
  font-weight: 800;
  position: fixed;
  width: 100%;
  padding: 16px 16px;
  top: 0;
  background-color: var(--color-grey700);
  z-index: 5;
}
