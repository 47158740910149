.Container {
  background-color: white;
  font-family: "Open Sans";
  height: auto;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: column;
  position: relative;
}

.Default {
  width: 390px;
  padding: 10px 40px 40px 40px;
  text-align: center;
  align-items: center;
}

.Container > h4 {
  font-family: "Marcellus";
  margin-top: 34px;
  font-size: 20;
  font-weight: 600;
  color: var(--color-grey900);
}

.Container > p {
  font-family: "Open Sans";
  margin-top: 10px;
  font-weight: 400;
  color: var(--color-grey500);
}

.Icon {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}