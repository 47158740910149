.Items {
  display: flex;
  margin-top: 50px;
  text-align: center;
}

.Item {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 10px 40px;
  align-items: center;
}

.Item > h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 16px 0;
  font-weight: 800;
}

.Item > p {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
}
.LearnMore {
  display: none;
}

@media only screen and (max-width: 900px) {
  .LearnMore {
    height: 60px;
    width: 150px;
    margin-top: 40px;
    margin-bottom: 30px;
    display: inline;
  }
  .Items {
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Item > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
  .Item > p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
}
