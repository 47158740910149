.Container {
  width: 100%;
  height: 0;
  position: relative;
  z-index: -1;
}

.BackDrop {
  width: 100%;
  height: 100%;
  position: relative;
}

.Overlay {
  background: linear-gradient(180deg, #344054 0%, rgba(68, 51, 90, 0.5) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 900px) {
  .Overlay {
    background: linear-gradient(
      180deg,
      #344054 0%,
      rgba(68, 51, 90, 0.5) 116.14%
    );
  }
}

.BackDrop > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
