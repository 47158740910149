.Container {
  margin-top: 130px;
}

.Filters {
  font-size: 14px;
  display: flex;
  margin: 16px 0;
  width: fit-content;
  margin-left: 16px;
}

.FiltersButton {
  color: var(--color-grey500);
  background: var(--color-grey100);
  border: 1px solid var(--color-grey300);
  box-sizing: border-box;
  border-radius: 100px;
  margin-right: 4px;
  padding: 6px 15px;
  font-weight: 600;
}

.FiltersButton path {
  fill: var(--color-grey500);
}

.FilterSelected {
  background-color: #475467;
  border: 1px solid #475467;
}

.FilterSelected * {
  color: white;
}

.Filters input {
  display: none;
}

.DraftIcon {
  display: inline-block;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: 6px;

  background-color: var(--color-orange0);
  border-radius: 50%;
  line-height: 20px;
  color: #ffffff;
}

.Lists {
  background-color: white;
  position: relative;
  margin-bottom: 70px;
}

.Lists > div:not(:last-child)::after {
  content: "";
  height: 1px;
  width: calc(100% - 90px);
  left: 66px;
  background-color: var(--color-grey200);
  position: absolute;
}
