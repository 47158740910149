.Container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.NewUserBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.NewUserBox > div {
  margin: 0 5px;
}

.Button {
  margin-top: 10px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.Title {
  line-height: 32px;
  font-weight: 700;
  color: var(--color-grey900);
  text-align: center;
}

h3.Title {
  margin-top: 56px;
  margin-bottom: 60px;
}

h4.Title {
  margin-bottom: 17px;
  font-weight: bold;
}

.Card {
  width: 384px;
}

.Scroll {
  overflow-y: scroll;
  height: calc(100vh - 106px);
  padding-bottom: 20px;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.Scroll::-webkit-scrollbar {
  display: none;
}

.SearchInputWrapper {
  width: 93vw;
  max-width: none;
}

.FirstAboutCard {
  position: relative;
  z-index: 10;
  background-color: var(--color-grey100);
  padding-bottom: 24px;
  padding-left: 20px;
  padding-top: 8px;
}

.WhiteAboutCard {
  position: relative;
  z-index: 1;
  background-color: white;
  padding-bottom: 24px;
  padding-left: 20px;
  padding-top: 24px;
}

@media only screen and (max-width: 900px) {
  .Scroll {
    overflow-y: visible;
    height: auto;
  }

  .NewUserBox > div {
    margin: 0;
  }

  .NewUserBox {
    margin-top: 120px;
    display: flex;
    flex-direction: column-reverse;
  }

  .Button {
    margin-top: 0px;
    margin-right: auto;
    margin-left: 0;
  }

  .Title {
    display: none;
  }
}
