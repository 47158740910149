.Container {
  display: flex;
  flex-flow: column;
  background-color: white;
  margin-bottom: 4px;
}

.NoMargin {
  margin-left: 25px;
}

.Container > div:not(:first-child) {
  margin-top: 15px;
}

.TitleContainer {
  display: flex;
  margin-left: 16px;
  align-items: center;
  padding-right: 16px;
  margin-top: 8px;
}

.HideIcon {
  margin-left: 0;
}

.HideIcon > .Title {
  padding: 0;
}

.Title {
  font-family: "Marcellus";
  display: block;
  padding: 0 16px;
  word-break: break-word;
  color: var(--color-grey700);
  font-size: 16px;
  font-weight: 800;
}

.Items {
  font-family: "Open Sans";
  overflow-x: hidden;
  cursor: pointer;
}

.TextNolist {
  margin-left: 20px;
  cursor: default;
}

.ItemFlex {
  display: flex;
  align-items: center;
}

.NoOverflow {
  overflow: hidden;
}

.InfoColumn {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.InfoRow {
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.InfoRow path {
  fill: var(--color-grey400);
}

.InfoRow svg {
  height: 16px;
  width: 16px;
}

.InfoRow > div {
  margin-left: 4px;
  margin-right: 8px;
  display: flex;
  line-height: 20px;
}

.InfoColumn > div {
  color: var(--color-grey400);
}

.InfoColumn > h5 {
  font-size: 14px;
  color: var(--color-grey700);
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
}

.Highlight::before {
  content: "";
  background-color: var(--color-grey100);
  position: absolute;
  padding: 32px 150px;
  left: 0px;
}

.Highlight {
  position: relative;
}

.ShowMore {
  margin-top: 0;
  text-align: left;
  margin-top: 8px;
  margin-left: 16px;
}

.Items a:not(:last-child) > div > div::after {
  content: "";
  background: var(--color-grey200);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1px;
  width: 230px;
}

.Icon {
  fill: var(--color-grey700);
}
