.Container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 20;
  background-color: var(--color-grey100);
}

.Tip {
  border-radius: 10px;
  margin: 25px 0px 0px 0px;
}

.Info {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  gap: 30px;
  margin-top: 20px;
}

.Info > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.Icon {
  width: 50px;
  height: 50px;
}
