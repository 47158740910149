.Header {
  font-family: "Marcellus";
  display: flex;
  flex-flow: column;
  justify-content: center;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  align-items: center;
  height: 290px;
}

.BackArrow {
  display: flex;
  fill: white;
  position: relative;
  margin-right: 5vw;
  z-index: 1;
}

.HeaderContent > h1 {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 900;
  background: -webkit-linear-gradient(
    45deg,
    rgba(253, 176, 34, 1),
    rgba(195, 85, 157, 1)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.HeaderContent > h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 30px;
}

.HeaderContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.ListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ListContainer > * {
  margin-top: 40px;
}

@media only screen and (max-width: 330px) {
  .BackArrow {
    margin-right: 250px;
  }
}

@media only screen and (max-width: 900px) {
  .HeaderContent > h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .ListContainer {
    margin-bottom: 40px;
  }

  .CityTitle {
    margin-right: 8vw;
  }

  .RESIcon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
  }
}
