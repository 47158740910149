.Background {
  background-color: var(--color-grey100);
}

.LoadingList {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Phone {
  display: none;
}
.Desktop {
  display: contents;
}

@media only screen and (max-width: 900px) {
  .Phone {
    display: contents;
  }
  .Desktop {
    display: none;
  }
}
